import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "amfe-flexible"
import "normalize.css"
import "@/assets/myself.css"
import wx from "weixin-js-sdk"

Vue.prototype.$domain='http://tiaoui.dingshenghuijin.cc'
// import { Dialog } from 'vant'

import { Icon } from 'vant';
// 全局注册
Vue.use(Icon);
// import axios from './axios'
import api from './api'

// // Toast
// import { showToast } from 'vant';
// import 'vant/es/toast/style';

// // Dialog
// import { showDialog } from 'vant';
// import 'vant/es/dialog/style';

// Notify
// import { showNotify } from 'vant';
// import 'vant/es/notify/style';

import dayjs from 'dayjs';     
Vue.prototype.$dayjs = dayjs;

// ImagePreview
// import { showImagePreview } from 'vant';
// import 'vant/es/image-preview/style';


// Vue.prototype.$axios = axios
Vue.prototype.$api = api

// 挂在vue的原型上方便使用
Vue.prototype.$wx = wx;


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
