import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import OrderList from '../views/OrderList.vue'
// import My from '../views/My.vue'
// import Login from '../views/Login.vue'
// import Msg from '../views/Msg.vue'

Vue.use(VueRouter)


const routes = [{
		path: '/',
		name: 'Home',
		meta: {
			title: "首页"
		},
		component: Home
	},
	{
		path: '/erji/car/car',
		name: 'Car',
		meta: {
			title: "车辆"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/car.vue')
	},{
		path: '/erji/car/carrer',
		name: 'Carrer',
		meta: {
			title: "副业"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/carrer.vue')
	},
	{
		path: '/erji/car/live',
		name: 'Live',
		meta: {
			title: "生活"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/live.vue')
	},{
		path: '/erji/car/people',
		name: 'People',
		meta: {
			title: "人事"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/people.vue')
	},{
		path: '/erji/car/room',
		name: 'Room',
		meta: {
			title: "房屋"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/room.vue')
	},{
		path: '/erji/car/shopping',
		name: 'Shopping',
		meta: {
			title: "代购"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/shopping.vue')
	},{
		path: '/erji/car/trade',
		name: 'Trade',
		meta: {
			title: "交易"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/trade.vue')
	}, 
	{
		path: '/info',
		name: 'Info',
		meta: {
			title: "个人资料"
		},
		// component: OrderList,
		component: () => import( /* webpackChunkName: "about" */ '../views/Info.vue')
	}, {
		path: '/orderlist',
		name: 'orderlist',
		meta: {
			title: "借条管理"
		},
		// component: OrderList,
		component: () => import( /* webpackChunkName: "about" */ '../views/OrderList.vue')
	}, {
		path: '/rulesample',
		name: 'RuleSample',
		meta: {
			title: "样例"
		},
		// component: OrderList,
		component: () => import( /* webpackChunkName: "about" */ '../views/RuleSample.vue')
	}, {
		path: '/login',
		name: 'Login',
		meta: {
			title: "登录/注册"
		},
		// component: Login,
		component: () => import( /* webpackChunkName: "about" */ '../views/Login.vue')
	}, {
		path: '/resetpwd',
		name: 'ResetPwd',
		meta: {
			title: "忘记密码"
		},
		// component: Login,
		component: () => import( /* webpackChunkName: "about" */ '../views/ResetPwd.vue')
	},{
		path: '/changepwd',
		name: 'ChangePwd',
		meta: {
			title: "重置登录密码"
		},
		// component: Login,
		component: () => import( /* webpackChunkName: "about" */ '../views/ChangePwd.vue')
	}, {
		path: '/changesafepwd',
		name: 'ChangeSafePwd',
		meta: {
			title: "重置交易密码"
		},
		// component: Login,
		component: () => import( /* webpackChunkName: "about" */ '../views/ChangeSafePwd.vue')
	}, {
		path: '/loginpwd',
		name: 'LoginPwd',
		meta: {
			title: "登录/注册"
		},
		// component: Login,
		component: () => import( /* webpackChunkName: "about" */ '../views/LoginPwd.vue')
	}, {
		path: '/loginsms',
		name: 'LoginSms',
		meta: {
			title: "登录/注册"
		},
		// component: Login,
		component: () => import( /* webpackChunkName: "about" */ '../views/LoginSms.vue')
	}, {
		path: '/my',
		name: 'my',
		meta: {
			title: "我的"
		},
		// component: My,
		component: () => import( /* webpackChunkName: "about" */ '../views/My.vue')
	}, {
		path: '/msg',
		name: 'msg',
		meta: {
			title: "消息中心"
		},
		// component: Msg,
		component: () => import( /* webpackChunkName: "about" */ '../views/Msg.vue')
	},
	{
		path: '/rule',
		name: 'Rule',
		meta: {
			title: "用户协议"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/Rule.vue')
	}, {
		path: '/question',
		name: 'Question',
		meta: {
			title: "常见问题"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/Question.vue')
	}, {
		path: '/safe',
		name: 'Safe',
		meta: {
			title: "常见问题"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/Safe.vue')
	}, {
		path: '/feedback',
		name: 'Feedback',
		meta: {
			title: "常见问题"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/Feedback.vue')
	}, {
		path: '/questioninfo',
		name: 'QuestionInfo',
		meta: {
			title: "热门问题"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/QuestionInfo.vue')
	}, {
		path: '/shiming',
		name: 'Shiming',
		meta: {
			title: "实名认证"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ShiMing.vue')
	}, {
		path: '/shiming2',
		name: 'Shiming2',
		meta: {
			title: "实名认证"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ShiMing2.vue')
	}, {
		path: '/shiming3',
		name: 'Shiming3',
		meta: {
			title: "实名认证"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ShiMing3.vue')
	}, {
		path: '/orderinfo',
		name: 'OrderInfo',
		meta: {
			title: "借条详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/OrderInfo.vue')
	},
	{
		path: '/dajietiao',
		name: 'Dajietiao',
		meta: {
			title: "打借条"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/DaJieTiao.vue')
	},
	{
		path: '/setting',
		name: 'Setting',
		meta: {
			title: "设置"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/Setting.vue')
	}, {
		path: '/msglist',
		name: 'Msglist',
		meta: {
			title: "列表"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/MsgList.vue')
	}, {
		path: '/msginfo',
		name: 'Msginfo',
		meta: {
			title: "消息详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/MsgInfo.vue')
	},
	{
		path: '/zhongcai',
		name: 'Zhongcai',
		meta: {
			title: "仲裁中心"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ZhongCai.vue')
	},
	{
		path: '/qiangzi',
		name: 'Qiangzi',
		meta: {
			title: "强执中心"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/QiangZi.vue')
	},
	{
		path: '/about',
		name: 'About',
		meta: {
			title: "关于"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
	},
	{
		path: '/erji/car/car/carChange',
		name: 'CarChange',
		meta: {
			title: "车辆"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/car/carChange.vue')
	},
	{
		path: '/erji/car/car/carBuy',
		name: 'CarBuy',
		meta: {
			title: ""
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/car/carBuy.vue')
	},
	{
		path: '/look',
		name: 'Look',
		meta: {
			title: "预览"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/look.vue')
	},
	{
		path: '/erji/car/car/changeBuy',
		name: 'ChangeBuy',
		meta: {
			title: "选择/填写对方"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/car/changeBuy.vue')
	},
	{
		path: '/contract',
		name: 'Contract',
		meta: {
			title: "合同列表"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/contract.vue')
	},
	{
		path: '/erji/car/room/roomChange',
		name: 'RoomChange',
		meta: {
			title: "房屋"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/room/roomChange.vue')
	},
	{
		path: '/erji/car/room/roomBuy',
		name: 'RoomBuy',
		meta: {
			title: ""
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/room/roomBuy.vue')
	},
	{
		path: '/erji/car/room/changeRoom',
		name: 'ChangeRoom',
		meta: {
			title: "选择/填写对方"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/trade/changeTrade.vue')
	},
	{
		path: '/erji/car/trade/tradeChange',
		name: 'TradeChange',
		meta: {
			title: "交易"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/trade/tradeChange.vue')
	},
	{
		path: '/erji/car/trade/tradeBuy',
		name: 'TradeBuy',
		meta: {
			title: ""
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/trade/tradeBuy.vue')
	},
	{
		path: '/erji/car/trade/changeTrade',
		name: 'ChangeTrade',
		meta: {
			title: "选择/填写对方"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/trade/changeTrade.vue')
	},
	{
		path: '/erji/car/live/liveChange',
		name: 'LiveChange',
		meta: {
			title: "生活"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/live/liveChange.vue')
	},
	{
		path: '/erji/car/live/liveBuy',
		name: 'LiveBuy',
		meta: {
			title: ""
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/live/liveBuy.vue')
	},
	{
		path: '/erji/car/live/changeLive',
		name: 'ChangeLive',
		meta: {
			title: "选择/填写对方"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/live/changeLive.vue')
	},
	{
		path: '/erji/car/carrer/carrerChange',
		name: 'CarrerChange',
		meta: {
			title: "副业"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/carrer/carrerChange.vue')
	},
	{
		path: '/erji/car/carrer/carrerBuy',
		name: 'CarrerBuy',
		meta: {
			title: ""
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/carrer/carrerBuy.vue')
	},
	{
		path: '/erji/car/carrer/changeCarrer',
		name: 'ChangeCarrer',
		meta: {
			title: "选择/填写对方"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/carrer/changeCarrer.vue')
	},
	{
		path: '/erji/car/shopping/shoppingChange',
		name: 'CarrerChange',
		meta: {
			title: "代购"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/shopping/shoppingChange.vue')
	},
	{
		path: '/erji/car/shopping/shoppingBuy',
		name: 'ShoppingBuy',
		meta: {
			title: ""
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/shopping/shoppingBuy.vue')
	},
	{
		path: '/erji/car/shopping/changeShopping',
		name: 'ChangeShopping',
		meta: {
			title: "选择/填写对方"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/shopping/changeShopping.vue')
	},
	{
		path: '/erji/car/people/peopleChange',
		name: 'PeopleChange',
		meta: {
			title: "人事"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/people/peopleChange.vue')
	},
	{
		path: '/erji/car/people/peopleBuy',
		name: 'PeopleBuy',
		meta: {
			title: ""
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/people/peopleBuy.vue')
	},
	{
		path: '/erji/car/people/changePeople',
		name: 'ChangePeople',
		meta: {
			title: "选择/填写对方"
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/erji/car/people/changePeople.vue')
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	// const ua = navigator.userAgent.toLowerCase();
	// if (
	//   to.path !== "/notwx" &&
	//   !(ua.match(/MicroMessenger/i) == "micromessenger")
	// ) {
	//   next("/notwx");
	// } else {
	//   next();
	// }
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next();
});


export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}


export default router