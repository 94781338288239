import {
	login,
	loginSms,
	logout,
	getInfo
} from '@/api/user'
import {
	getToken,
	setToken,
	removeToken
} from '@/utils/auth'
import {
	resetRouter
} from '@/router'

const getDefaultState = () => {
	return {
		token: getToken(),
		name: '',
		avatar: '',
		phone: '',
		isMing:0
	}
}

const state = getDefaultState()

const mutations = {
	RESET_STATE: (state) => {
		Object.assign(state, getDefaultState())
	},
	SET_ROLES: (state, roles) => {
		state.roles = roles
	},
	SET_TOKEN: (state, token) => {
		state.token = token
	},
	SET_MING: (state, isMing) => {
		state.isMing = isMing
	},
	SET_PHONE: (state, phone) => {
		state.phone = token
	},
	SET_NAME: (state, name) => {
		state.name = name
	},
	SET_AVATAR: (state, avatar) => {
		state.avatar = avatar
	},
	SET_SHOP_ID: (state, shop_id) => {
		state.shop_id = shop_id
	},
	SET_GZ: (state, gz) => {
		state.gz = gz
	},
	SET_MONEY: (state, money) => {
		state.money = money
	}
}

const actions = {
	// user login
	login({
		commit
	}, userInfo) {
		console.log(userInfo)
		const {
			phone,
			password,
			loginBy,
			code
		} = userInfo

		return new Promise((resolve, reject) => {
			let uInfo = JSON.parse(localStorage.getItem("xinguserInfo"))
			if (!uInfo) {
				console.log('微信未授权登录')
			}
			let tmp = {
				phone: phone.trim(),
				password: password,
				code: code,
				openid: uInfo.openid,
				headimg: uInfo.headimgurl
			}
			console.log(tmp)
			login(tmp).then(response => {
				if (response.code != 200) {
					reject(response.message)
				}
				const {
					data
				} = response
				console.log(1111)
				console.log(data)
				commit('SET_TOKEN', data.token)
				commit('SET_MING', data.isMing)
				setToken(data.token)
				resolve(data)
			}).catch(error => {
				reject(error)
			})

		})
	},
	loginSms({
		commit
	}, userInfo) {
		console.log(userInfo)
		const {
			phone,
			password,
			loginBy,
			code
		} = userInfo
		console.log(code)
		return new Promise((resolve, reject) => {
			console.log('promise')
			let uInfo = JSON.parse(localStorage.getItem("xinguserInfo"))
			console.log(uInfo)
			let tmp = {
				phone: phone.trim(),
				password: password,
				code: code,
				openid: uInfo.openid,
				headimg: uInfo.headimgurl
			}

			console.log(tmp)
			loginSms(tmp).then(response => {
				if (response.code != 200) {
					reject(response.message)
				}

				const {
					data
				} = response
				commit('SET_TOKEN', data.token)
				commit('SET_MING', data.isMing)
				setToken(data.token)
				resolve(data)
			}).catch(error => {
				reject(error)
			})

		})
	},

	// get user info
	getInfo({
		commit,
		state
	}) {
		return new Promise((resolve, reject) => {
			getInfo(state.token).then(response => {
				const {
					data
				} = response

				if (!data) {
					return reject('Verification failed, please Login again.')
				}

				const {
					roles,
					name,
					avatar,
					shop_id,
					money,
					phone
				} = data
				// commit('SET_ROLES', roles)
				commit('SET_NAME', name)
				commit('SET_AVATAR', avatar)
				commit('SET_PHONE', phone)
				// commit('SET_SHOP_ID', shop_id)
				commit('SET_MONEY', money)
				resolve(data)
			}).catch(error => {
				reject(error)
			})
		})
	},

	// user logout
	logout({
		commit,
		state
	}) {
		return new Promise((resolve, reject) => {
			logout(state.token).then(() => {
				removeToken() // must remove  token  first
				// resetRouter()
				commit('RESET_STATE')
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},

	// remove token
	resetToken({
		commit
	}) {
		return new Promise(resolve => {
			removeToken() // must remove  token  first
			commit('RESET_STATE')
			resolve()
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
