import "core-js/modules/es.array.push.js";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import index_bg from '@/assets/homenew/index_bg.png'
import index_top from '@/assets/homenew/index_top.png';
import menuimg1 from '@/assets/homenew/menuimg1.png';
import menuimg2 from '@/assets/homenew/menuimg2.png';
import menuimg3 from '@/assets/homenew/menuimg3.png';
import menuimg4 from '@/assets/homenew/menuimg4.png';
import menuimg5 from '@/assets/homenew/menuimg5.png';
import menuimg6 from '@/assets/homenew/menuimg6.png';
import menuimg7 from '@/assets/homenew/menuimg7.png';
import menuimg8 from '@/assets/homenew/menuimg8.png';
import left_bar from '@/assets/homenew/left_bar.png';
import omimg1 from '@/assets/homenew/omimg1.png';
import omimg2 from '@/assets/homenew/omimg2.png';
import omimg3 from '@/assets/homenew/omimg3.png';
import omimg4 from '@/assets/homenew/omimg4.png';
import omimg5 from '@/assets/homenew/omimg5.png';
import omimg6 from '@/assets/homenew/omimg6.png';
import { getAlerts } from '@/api/msg';
export default {
  name: 'Home',
  title: 'Home',
  components: {
    // HelloWorld
  },
  data() {
    return {
      background: {
        // 背景图片地址
        backgroundImage: 'url(' + require('../assets/homenew/index_bg.png') + ')',
        // 背景图片是否重复
        backgroundRepeat: 'no-repeat',
        // 背景图片大小
        backgroundSize: 'cover'
        // 背景颜色
        // backgroundColor: '#000',
        // 背景图片位置
        // backgroundPosition: 'center top'
      },

      background2: {
        // 背景图片地址
        backgroundImage: 'url(' + require('../assets/homenew/ombg1.png') + ')',
        // 背景图片是否重复
        backgroundRepeat: 'no-repeat',
        // 背景图片大小
        backgroundSize: 'cover'
        // 背景颜色
        // backgroundColor: '#000',
        // 背景图片位置
        // backgroundPosition: 'center top'
      },

      alerts: [],
      show: true,
      omimg1,
      omimg2,
      omimg3,
      omimg4,
      omimg5,
      omimg6,
      left_bar,
      index_top,
      menuimg1,
      menuimg2,
      menuimg3,
      menuimg4,
      menuimg5,
      menuimg6,
      menuimg7,
      menuimg8
    };
  },
  created() {
    let uInfo = JSON.parse(localStorage.getItem("xinguserInfo"));
    if (uInfo && uInfo.isMing) {
      // this.show =false
    }
    // getAlerts().then(resp => {
    // 	this.alerts = resp.data
    // })
  },

  methods: {
    click(name) {
      console.log(name);
      //this.$refs.uToast.success(`点击了第${name}个`)
    },

    goda() {
      this.$router.push('dajietiao');
    },
    gocarrer() {
      this.$router.push('erji/car/carrer');
    },
    golive() {
      this.$router.push('erji/car/live');
    },
    gopeople() {
      this.$router.push('erji/car/people');
    },
    goroom() {
      this.$router.push('erji/car/room');
    },
    goshop() {
      this.$router.push('erji/car/shopping');
    },
    gotrade() {
      this.$router.push('erji/car/trade');
    },
    jump() {
      this.$router.push('orderlist');
    },
    jump2() {
      this.$router.push('shiming');
    },
    jump3() {
      this.$router.push('zhongcai');
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    }
  }
};