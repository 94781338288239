import { getWxUserInfo, getWxConfig, isHasWxToken } from '@/api/order';
export default {
  name: 'Home',
  data() {
    return {
      code: ''
    };
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  created() {
    console.log('app created');
    let uInfo = JSON.parse(localStorage.getItem("xinguserInfo"));
    if (uInfo && uInfo.openid) {
      this.getWxJssdkConf(uInfo.openid);
    } else {
      this.getCode();
    }
  },
  methods: {
    /**
     * @description: 获取授权code
     * @param {*}
     * @return {*}
     */
    getCode() {
      // 从 window.location.href 中截取 code 并且赋值
      // window.location.href.split('#')[0]
      if (window.location.href.indexOf("state") !== -1) {
        // this.code = qs.parse(
        // 	window.location.href.split("#")[0].split("?")[1]
        // ).code;
        this.code = this.$route.query.code;
      }
      if (this.code) {
        // 存在 code 直接调用接口
        this.handGetUserInfo(this.code);
      } else {
        this.handLogin();
      }
    },
    /**
     * @description: 获取用户授权登陆
     * @param {*}
     * @return {*}
     */
    handLogin() {
      console.log('handLogin');
      let appid = 'wx21b455459005e90d';
      let hrefUrl = window.location.href;
      console.log(`
					https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}
				                       &redirect_uri=${encodeURIComponent(hrefUrl)}
				                       &response_type=code
				                       &scope=snsapi_userinfo
				                       &state=h5#wechat_redirect`);
      // 重定向地址重定到当前页面，在路径获取 code

      // hrefUrl = 'https://tiao.dingshenghuijin.cc/index/getTestCode'

      if (this.code === "") {
        console.log('replace');
        window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(hrefUrl)}&response_type=code&scope=snsapi_userinfo&state=h5#wechat_redirect`);
      }
    },
    /**
     * @description: 获取用户信息
     * @param {*} code
     * @return {*}
     */
    handGetUserInfo(code) {
      // 调用后台接口
      let param = {
        code
      };
      getWxUserInfo(param).then(resp => {
        if (resp.code == 200) {
          this.uInfo = Object.assign({}, this.uInfo, resp.data);
          localStorage.setItem("xinguserInfo", JSON.stringify(this.uInfo));
        }
      });
    },
    updateOnlineStatus(e) {
      const {
        type
      } = e;
      console.log("type==============", type);
      this.onLine = type === "online";
    },
    async getWxJssdkConf(openid) {
      const res = await this.$api.getSdkConfig({
        url: window.location.href.split("#")[0],
        openid: openid
      });
      if (res.success) {
        this.$wx.config({
          debug: false,
          // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.data.appId,
          // 必填，公众号的唯一标识
          timestamp: String(res.data.timestamp),
          // 必填，生成签名的时间戳
          nonceStr: String(res.data.nonceStr),
          // 必填，生成签名的随机串
          signature: res.data.signature,
          // 必填，签名，见附录1
          jsApiList: ["getNetworkType", "chooseWXPay", "updateAppMessageShareData"] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });

        this.$wx.ready(() => {
          console.log('app.vue ready');
          this.$wx.checkJsApi({
            jsApiList: ["getNetworkType", "getLocation"],
            // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function (res) {
              console.log("checkJsApi", res);
            }
          });
        });
        this.$wx.error(res => {
          console.log("wx.error", res);
        });
      }
    }
  }
};