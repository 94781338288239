import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import {Toast} from 'vant'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: 'https://tiao.dingshenghuijin.cc', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      config.headers['token'] = getToken()
    }
	// config.headers['token'] = 'aab'
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
	console.log(res)
    // if the custom code is not 20000, it is judged as an error.
	
	
	
    if (res.code !== 200) {
		Toast.fail(res.message);
		// 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
		if (res.code === 5008 || res.code === 50012 || res.code === 50014||res.code==-1) {
		  // to re-login
		 store.dispatch('user/resetToken').then(() => {
		   location.reload()
		 })
		}else{
			
		}
		
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
	return res
  },
  error => {
    console.log('err' + error) // for debug
   
    return Promise.reject(error)
  }
)

export default service
