import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/admin/user/login',
    method: 'post',
    data
  })
}

export function getInvoicingContractList(params) {
  return request({
    url: '/user/msg',
    method: 'get',
    params:params
  })
}
export function getInfo(params) {
  return request({
    url: '/msg/info',
    method: 'get',
    params
  })
}

export function getAlerts() {
  return request({
    url: '/index/getAlerts',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/admin/admin/logout',
    method: 'post'
  })
}
