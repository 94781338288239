import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}

export function getLoginCode(data) {
  return request({
    url: '/user/getSmsCode',
    method: 'post',
    data
  })
}
export function loginSms(data) {
  return request({
    url: '/user/loginBySms',
    method: 'post',
    data
  })
}
export function postSafePwd(data) {
  return request({
    url: '/user/changeSafePwd',
    method: 'post',
    data
  })
}
export function postPwd(data) {
  return request({
    url: '/user/changePwd',
    method: 'post',
    data
  })
}
export function resetPwd(data) {
  return request({
    url: '/index/forgotPwd',
    method: 'post',
    data
  })
}
export function postShiming(data) {
  return request({
    url: '/user/shiming',
    method: 'post',
    data
  })
}
export function postShiming2(data) {
  return request({
    url: '/user/shiming2',
    method: 'post',
    data
  })
}
export function postShiming3(data) {
  return request({
    url: '/user/shiming3',
    method: 'post',
    data
  })
}

export function getCode(data) {
  return request({
    url: '/user/getShimingCode',
    method: 'post',
    data
  })
}
export function getCodeByType(data) {
  return request({
    url: '/user/getSmsCode',
    method: 'post',
    data
  })
}

export function getUserData(data) {
  return request({
    url: '/user/getUserData',
    method: 'post',
    data
  })
}

export function getUserInfo(data) {
  return request({
    url: '/user/getUserInfoDetail',
    method: 'post',
    data
  })
}




export function getInvoicingContractList(params) {
  return request({
    url: '/user/orders',
    method: 'get',
    params:params
  })
}
export function getInfo(token) {
  return request({
    url: '/user/info',
    method: 'get',
    params: { token }
  })
}

export function getMsgList(params) {
  return request({
    url: '/user/getMsgList',
    method: 'get',
    params: params
  })
}

export function logout() {
  return request({
    url: '/user/logout',
    method: 'post'
  })
}

export function delUser(data) {
  return request({
    url: '/user/delUser',
    method: 'post',
	data
  })
}
export function setPwd(data) {
  return request({
    url: '/user/setPwd',
    method: 'post',
	data
  })
}
export function submitJietiao(data) {
  return request({
    url: '/user/submitJietiao',
    method: 'post',
	data
  })
}
export function getPayConfigOther(data) {
  return request({
    url: '/pay/getPayConfigOther',
    method: 'post',
	data
  })
}
export function submitcar(data) {
  return request({
    url: '/user/submitcar',
    method: 'post',
	data
  })
}