const getters = {
  // sidebar: state => state.app.sidebar,
  // device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  gz: state => state.user.gz,
  money: state => state.user.money,
  isMing: state => state.user.isMing,
}
export default getters
