import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/admin/user/login',
    method: 'post',
    data
  })
}

export function getInvoicingContractList(params) {
  return request({
    url: '/user/msg',
    method: 'get',
    params:params
  })
}
export function getInfo(params) {
  return request({
    url: '/order/info',
    method: 'get',
    params
  })
}
export function getWxUserInfo(params) {
  return request({
    url: '/pay/getWxUserInfo',
    method: 'get',
    params
  })
}
export function getPayConfig(params) {
  return request({
    url: '/user/getPayConfig',
    method: 'post',
    params
  })
}
export function getWxConfig(data) {
  return request({
    url: '/user/getWxConfig',
    method: 'post',
    data
  })
}
export function isHasWxToken(data) {
  return request({
    url: '/user/isHasWxToken',
    method: 'post',
    data
  })
}
export function uploadAvatar(data) {
  return request({
    url: '/user/uploadAvatar',
    method: 'post',
    data
  })
}
export function userCancelOrder(data) {
  return request({
    url: '/user/cancelOrder',
    method: 'post',
    data
  })
}
export function agreeOrder(data) {
  return request({
    url: '/user/agreeOrder',
    method: 'post',
    data
  })
}
export function finishOrder(data) {
  return request({
    url: '/user/finishOrder',
    method: 'post',
    data
  })
}
export function finishOrderPart(data) {
  return request({
    url: '/user/finishOrderPart',
    method: 'post',
    data
  })
}
export function applyFinishOrder(data) {
  return request({
    url: '/user/applyFinishOrder',
    method: 'post',
    data
  })
}
export function applyFinishOrderCancel(data) {
  return request({
    url: '/user/applyFinishOrderCancel',
    method: 'post',
    data
  })
}

export function rejectFinishOrder(data) {
  return request({
    url: '/user/rejectFinishOrder',
    method: 'post',
    data
  })
}
export function applyDelayOrder(data) {
  return request({
    url: '/user/applyDelayOrder',
    method: 'post',
    data
  })
}
export function cancelDelay(data) {
  return request({
    url: '/user/cancelDelay',
    method: 'post',
    data
  })
}
export function agreeDelay(data) {
  return request({
    url: '/user/agreeDelay',
    method: 'post',
    data
  })
}
export function rejectDelay(data) {
  return request({
    url: '/user/rejectDelay',
    method: 'post',
    data
  })
}

export function getLookUrl(data) {
  return request({
    url: '/index/getSignRuleUrl',
    method: 'post',
    data
  })
}

export function delOrderById(id) {
  return request({
    url: '/user/delOrderById',
    method: 'get',
    params: { id }
  })
}

export function isGz(data) {
  return request({
    url: '/pay/isgz',
    method: 'post',
	data
  })
}
export function logout() {
  return request({
    url: '/admin/admin/logout',
    method: 'post'
  })
}
