var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bgBackground block-top",
    style: _vm.background
  }, [_c('van-image', {
    staticStyle: {
      "margin-top": "1rem"
    },
    attrs: {
      "src": _vm.index_top
    }
  }), _c('div', {
    staticStyle: {
      "position": "relative",
      "top": "-3.5rem"
    }
  }, [_c('van-grid', {
    staticStyle: {
      "padding-left": "0.8rem",
      "padding-right": "0.8rem"
    },
    attrs: {
      "clickable": "",
      "border": false,
      "column-num": 4
    }
  }, [_c('van-grid-item', {
    attrs: {
      "to": "/dajietiao"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "27",
      "height": "29",
      "src": _vm.menuimg1
    }
  }), _c('div', {
    staticStyle: {
      "font-size": "0.35rem",
      "margin-top": "0.2rem"
    }
  }, [_vm._v("借款合同")])], 1), _c('van-grid-item', {
    attrs: {
      "to": "/erji/car/car"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "34px",
      "height": "26px",
      "src": _vm.menuimg2
    }
  }), _c('div', {
    staticStyle: {
      "font-size": "0.35rem",
      "margin-top": "0.2rem"
    }
  }, [_vm._v("车辆")])], 1), _c('van-grid-item', {
    attrs: {
      "to": "/erji/car/room"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "32px",
      "height": "26px",
      "src": _vm.menuimg3
    }
  }), _c('div', {
    staticStyle: {
      "font-size": "0.35rem",
      "margin-top": "0.2rem"
    }
  }, [_vm._v("房屋")])], 1), _c('van-grid-item', {
    attrs: {
      "to": "/erji/car/people"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "22px",
      "height": "27px",
      "src": _vm.menuimg4
    }
  }), _c('div', {
    staticStyle: {
      "font-size": "0.35rem",
      "margin-top": "0.2rem"
    }
  }, [_vm._v("人事")])], 1), _c('van-grid-item', {
    attrs: {
      "to": "/erji/car/trade"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "33px",
      "height": "26px",
      "src": _vm.menuimg5
    }
  }), _c('div', {
    staticStyle: {
      "font-size": "0.35rem",
      "margin-top": "0.2rem"
    }
  }, [_vm._v("买卖")])], 1), _c('van-grid-item', {
    attrs: {
      "to": "/erji/car/live"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "30px",
      "height": "26px",
      "src": _vm.menuimg6
    }
  }), _c('div', {
    staticStyle: {
      "font-size": "0.35rem",
      "margin-top": "0.2rem"
    }
  }, [_vm._v("生活")])], 1), _c('van-grid-item', {
    attrs: {
      "to": "/erji/car/carrer"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "26px",
      "height": "26px",
      "src": _vm.menuimg7
    }
  }), _c('div', {
    staticStyle: {
      "font-size": "0.35rem",
      "margin-top": "0.2rem"
    }
  }, [_vm._v("副业")])], 1), _c('van-grid-item', {
    staticStyle: {
      "background": "none"
    },
    attrs: {
      "to": "/erji/car/shopping"
    }
  }, [_c('van-image', {
    staticStyle: {
      "background": "none"
    },
    attrs: {
      "width": "30px",
      "height": "26px",
      "src": _vm.menuimg8
    }
  }), _c('div', {
    staticStyle: {
      "font-size": "0.35rem",
      "margin-top": "0.2rem"
    }
  }, [_vm._v("代购")])], 1)], 1), _c('div', {
    staticStyle: {
      "display": "flex",
      "font-size": "0.4rem",
      "align-items": "center",
      "padding-left": "10px",
      "margin-bottom": "0.4rem",
      "margin-top": "0.5rem"
    }
  }, [_c('van-image', {
    attrs: {
      "width": "5px",
      "height": "20px",
      "src": _vm.left_bar
    }
  }), _c('div', {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v("可信服务")])], 1), _c('van-row', [_c('van-col', {
    staticStyle: {
      "margin-right": "3%",
      "margin-left": "3%"
    },
    attrs: {
      "span": "11"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "padding-right": "0rem",
      "padding-left": "0.2rem",
      "justify-content": "center",
      "border": "1px solid #eee",
      "border-radius": "5px"
    }
  }, [_c('div', {
    staticStyle: {
      "flex-grow": "1",
      "padding-top": "0.4rem"
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "0.3rem",
      "color": "#3F69B2"
    }
  }, [_vm._v("查信用")]), _c('div', {
    staticStyle: {
      "font-size": "0.25rem",
      "margin-top": "0.2rem",
      "margin-bottom": "0rem"
    }
  }, [_vm._v("信用报告更放心")])]), _c('div', {
    staticStyle: {
      "width": "1.8rem",
      "height": "100%",
      "text-align": "right",
      "padding-right": "0.5rem",
      "padding-top": "0.2rem",
      "padding-bottom": "0.2rem"
    },
    style: _vm.background2
  }, [_c('van-image', {
    attrs: {
      "width": "25px",
      "height": "33px",
      "src": _vm.omimg1
    }
  })], 1)])]), _c('van-col', {
    attrs: {
      "span": "11"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "padding-right": "0rem",
      "padding-left": "0.2rem",
      "justify-content": "center",
      "border": "1px solid #eee",
      "border-radius": "5px"
    }
  }, [_c('div', {
    staticStyle: {
      "flex-grow": "1",
      "padding-top": "0.4rem"
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "0.3rem",
      "color": "#3F69B2"
    }
  }, [_vm._v("发律师函")]), _c('div', {
    staticStyle: {
      "font-size": "0.25rem",
      "margin-top": "0.2rem"
    }
  }, [_vm._v("敦促履约")])]), _c('div', {
    staticStyle: {
      "width": "1.8rem",
      "height": "100%",
      "text-align": "right",
      "padding-right": "0.5rem",
      "padding-top": "0.2rem",
      "padding-bottom": "0.2rem"
    },
    style: _vm.background2
  }, [_c('van-image', {
    attrs: {
      "width": "25px",
      "height": "33px",
      "src": _vm.omimg2
    }
  })], 1)])]), _c('van-col', {
    attrs: {
      "span": "1"
    }
  })], 1), _c('van-row', {
    staticStyle: {
      "margin-top": "0.2rem"
    }
  }, [_c('van-col', {
    staticStyle: {
      "margin-right": "3%",
      "margin-left": "3%"
    },
    attrs: {
      "span": "11"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "padding-right": "0rem",
      "padding-left": "0.2rem",
      "justify-content": "center",
      "border": "1px solid #eee",
      "border-radius": "5px"
    }
  }, [_c('div', {
    staticStyle: {
      "flex-grow": "1",
      "padding-top": "0.4rem"
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "0.3rem",
      "color": "#3F69B2"
    }
  }, [_vm._v("履约提醒")]), _c('div', {
    staticStyle: {
      "font-size": "0.25rem",
      "margin-top": "0.2rem",
      "margin-bottom": "0rem"
    }
  }, [_vm._v("到期提醒，防遗忘")])]), _c('div', {
    staticStyle: {
      "width": "1.8rem",
      "height": "100%",
      "text-align": "right",
      "padding-right": "0.5rem",
      "padding-top": "0.2rem",
      "padding-bottom": "0.2rem"
    },
    style: _vm.background2
  }, [_c('van-image', {
    attrs: {
      "width": "25px",
      "height": "33px",
      "src": _vm.omimg3
    }
  })], 1)])]), _c('van-col', {
    attrs: {
      "span": "11"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "padding-right": "0rem",
      "padding-left": "0.2rem",
      "justify-content": "center",
      "border": "1px solid #eee",
      "border-radius": "5px"
    }
  }, [_c('div', {
    staticStyle: {
      "flex-grow": "1",
      "padding-top": "0.4rem"
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "0.3rem",
      "color": "#3F69B2"
    }
  }, [_vm._v("一键仲裁")]), _c('div', {
    staticStyle: {
      "font-size": "0.25rem",
      "margin-top": "0.2rem"
    }
  }, [_vm._v("仲裁凭证，有保障")])]), _c('div', {
    staticStyle: {
      "width": "1.8rem",
      "height": "100%",
      "text-align": "right",
      "padding-right": "0.5rem",
      "padding-top": "0.2rem",
      "padding-bottom": "0.2rem"
    },
    style: _vm.background2
  }, [_c('van-image', {
    attrs: {
      "width": "25px",
      "height": "33px",
      "src": _vm.omimg4
    }
  })], 1)])]), _c('van-col', {
    attrs: {
      "span": "1"
    }
  })], 1), _c('van-row', {
    staticStyle: {
      "margin-top": "0.2rem"
    }
  }, [_c('van-col', {
    staticStyle: {
      "margin-right": "3%",
      "margin-left": "3%"
    },
    attrs: {
      "span": "11"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "padding-right": "0rem",
      "padding-left": "0.2rem",
      "justify-content": "center",
      "border": "1px solid #eee",
      "border-radius": "5px"
    }
  }, [_c('div', {
    staticStyle: {
      "flex-grow": "1",
      "padding-top": "0.4rem"
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "0.3rem",
      "color": "#3F69B2"
    }
  }, [_vm._v("申请出证")]), _c('div', {
    staticStyle: {
      "font-size": "0.25rem",
      "margin-top": "0.2rem",
      "margin-bottom": "0rem"
    }
  }, [_vm._v("快速出证，有效率")])]), _c('div', {
    staticStyle: {
      "width": "1.8rem",
      "height": "100%",
      "text-align": "right",
      "padding-right": "0.5rem",
      "padding-top": "0.2rem",
      "padding-bottom": "0.2rem"
    },
    style: _vm.background2
  }, [_c('van-image', {
    attrs: {
      "width": "25px",
      "height": "33px",
      "src": _vm.omimg5
    }
  })], 1)])]), _c('van-col', {
    attrs: {
      "span": "11"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "padding-right": "0rem",
      "padding-left": "0.2rem",
      "justify-content": "center",
      "border": "1px solid #eee",
      "border-radius": "5px"
    }
  }, [_c('div', {
    staticStyle: {
      "flex-grow": "1",
      "padding-top": "0.4rem"
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "0.3rem",
      "color": "#3F69B2"
    }
  }, [_vm._v("申请维权")]), _c('div', {
    staticStyle: {
      "font-size": "0.25rem",
      "margin-top": "0.2rem"
    }
  }, [_vm._v("平台协助维权")])]), _c('div', {
    staticStyle: {
      "width": "1.8rem",
      "height": "100%",
      "text-align": "right",
      "padding-right": "0.5rem",
      "padding-top": "0.2rem",
      "padding-bottom": "0.2rem"
    },
    style: _vm.background2
  }, [_c('van-image', {
    attrs: {
      "width": "25px",
      "height": "33px",
      "src": _vm.omimg6
    }
  })], 1)])]), _c('van-col', {
    attrs: {
      "span": "1"
    }
  })], 1)], 1), _c('van-tabbar', {
    attrs: {
      "route": ""
    }
  }, [_c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/",
      "icon": "home-o"
    }
  }, [_vm._v("首页")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/orderlist",
      "icon": "after-sale"
    }
  }, [_vm._v("合同")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/msg",
      "icon": "chat-o"
    }
  }, [_vm._v("消息")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/my",
      "icon": "user-o"
    }
  }, [_vm._v("我的")])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };